import React from "react";

const Header = ({
  bigImage,
  smallImage,
  heading,
  paragraph1,
  paragraph2,
  pageHeading,
  subHeading,
  para,
}) => {
  return (
    <div className="nx__header-wrapper w-80-wrapper mb-3em">
      {/* <div className="nx__image-wrapper">
        <div className="big-image-wrapper">
          <img src={bigImage} alt="" className="desktop-wrapper" />
          <img src={smallImage} alt="" className="mobile-wrapper" />
          <div className="header-text-wrapper">
            <h1 className="big-heading uppercase-text margin-0">
              {pageHeading}
            </h1>
            <p className="title fw-400 margin-0">{subHeading}</p>
            <p className="semi-para fw-400 margin-0">{para}</p>
          </div>
        </div>
      
      </div> */}
      <div className="nx__heading goken-gray-text">
        <h1 className="semi-page-heading titlecase-text goken-gray-text">
          {heading}
        </h1>
        <div className="red-underline"></div>
        <div className="para-wrapper">
          <p className="para margin-0">{paragraph1}</p>
          <p className="para para-2 margin-0">{paragraph2}</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
